<template>
  <div id="user-top-desktop" class="border-radius-bottom hidden-xs hidden-sm mr-20 flex" style="float:right;">
    <div class="hut-rewards--flat-button">
      <a
        class="phone-button"
        href="tel:5515153737">
        <img src="../../assets/images/icons/phone.svg" alt="Track Order" title="Track Order"
             class="llamar-phone" width="20">
        <span class="llamar-label">
          55 1515 3737
        </span>
      </a>
    </div>
<!--    <div class="hut-rewards&#45;&#45;flat-button">-->
<!--      <router-link to="/tracking">-->
<!--        <img src="https://static.phdvasia.com/mx/icons/track_your_order.svg" alt="Track Order" title="Track Order"-->
<!--             class="img-track" width="25"><strong>{{$t('track_your_order')}}</strong>-->
<!--      </router-link>-->
<!--    </div>-->

    <div class="hut-rewards--flat-button">
      <div v-if="!$auth.check()" class="login-box">
        <router-link to="/login">
          <img src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class="" width="22">
          <strong>{{ $t("login_title") }}</strong>
        </router-link>
      </div>
      <div v-if="$auth.check()" class="login-box">
        <router-link to="/profile">
          <strong v-html="$t('full_name_format', {first_name:$auth.user().first_name, last_name:$auth.user().last_name})"></strong>
        </router-link>
      </div>
    </div>
    <div class="hut-rewards--flat-button">
      <div v-if="!$auth.check()" class="login-box">
        <router-link to="/register">
          <img src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class="" width="22">
          <strong>{{ $t("login_register")}}</strong>
        </router-link>
      </div>
      <div v-if="$auth.check()" class="login-box">
        <div class="flag-inner">
          <a v-on:click="$root.$emit('logout')"><strong>{{ $t("button_logout")}}</strong></a>
        </div>
      </div>
    </div>

    <div v-for="(item, index) in langList"
      class="hut-rewards--flat-button" :key="index">
      <div v-if="$const.LANGUAGES[item]"
        class="flag-inner">
        <a @click="setLang(item)" :data-synth="'language-' + item">
          <img :src="$const.LANGUAGES[item]['image']"
               class="xs-mr-5">
          <span>{{ $const.LANGUAGES[item]['text'] }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

export default {
  mounted: function () {
    this.$cart.init(this)
    var vm = this
    this.$root.$on('cartUpdate', () => {
      vm.$cart.init(vm)
      vm.amount = vm.getTotalCartItem(vm.cart)
    })
    vm.amount = this.getTotalCartItem(this.cart)
  },
  destroyed () {
    this.$root.$off('cartUpdate')
  },
  data () {
    return {
      amount: 0,
      languages: this.$const.LANGUAGES
    }
  },
  computed: {
    ...mapGetters({
      langList: 'langList'
    }),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  methods: {
    setLang: function (lang) {
      this.$store.dispatch('setLang', lang)
      this.$session.set('lang', lang)
      this.$router.go(this.$router.currentRoute)
    }
  }
}

</script>

<style>
.phone-button {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 21px;
  text-transform: uppercase;
  width: 190px;
  top: 0px;
  float: right;
}
.phone-button .llamar-label {
  position: 'relative';
}
.phone-button .llamar-phone {
  position: 'relative';
  margin-right: 0.5em;
  width: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .phone-button {
    width: 30px !important;
    height: 30px;
    text-transform: uppercase;
    background-size: 35% auto;
    background-repeat: no-repeat;
    background-position: 10px 8px;
    top: 4px;
    right: 90px;
    min-height: 30px;
  }
}
</style>
